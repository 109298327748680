<template>
  <select
    class="
      rounded-lg
      border-transparent
      flex-1
      appearance-none
      border border-gray-300
      w-full
      py-2
      px-4
      bg-white
      text-gray-700
      placeholder-gray-400
      shadow-sm
      text-base
      focus:outline-none
      focus:ring-2
      focus:ring-purple-600
      focus:border-transparent
      my-1
    "
    :style="disabled === true ? 'opacity:0.3' : ''"
    :disabled="disabled"
    @update:modelValue="$emit('input', $event.target.value)"
  >
    <option
      v-for="(value, keyName) in values"
      :key="keyName"
      :value="keyName"
      :hidden="value === 'Please Select...'"
    >
      {{ value }}
    </option>
  </select>
</template>

<script>
export default {
  emits: ["on-change"],
  props: {
    selectName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: String,
    },
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: "",
    };
  },
};
</script>
