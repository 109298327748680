<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center flex-wrap content-evenly items-center">
    <small-form
      :title="routerTitle"
      instructions="Enter the new room name"
      :inputs="formItems"
      @resolve-form-submit="addRoom"
      :errorMessage="newRoomError"
    >
      <drop-down
        selectName="practice"
        :values="getPractices"
        :onChange="resolveOnChange"
      />
      <select
        class="
          material-icons
          rounded-lg
          border-transparent
          flex-1
          appearance-none
          border border-gray-300
          w-full
          py-2
          px-4
          bg-white
          text-gray-700
          placeholder-gray-400
          shadow-sm
          text-base
          focus:outline-none
          focus:ring-2
          focus:ring-purple-600
          focus:border-transparent
          my-1
        "
        name="icon"
        v-model="icon"
      >
        <option value="home" class="material-icons" selected>home</option>
        <option value="meeting_room" class="material-icons">
          meeting_room
        </option>
        <option value="family_restroom" class="material-icons">
          family_restroom
        </option>
        <option value="wc" class="material-icons">wc</option>
        <option value="flatware" class="material-icons">flatware</option>
        <option value="chair" class="material-icons">chair</option>
        <option value="weekend" class="material-icons">weekend</option>
        >
      </select>
    </small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <card-widget
          cardTitle="Rooms"
          icon="meeting_room"
          :cardValue="getRooms.total"
        >
        </card-widget>
        <card-widget></card-widget>
      </div>
      <div class="flex justify-center flex-wrap">
        <card-widget></card-widget>
        <card-widget></card-widget>
      </div>
    </div>
  </div>
  <template v-if="!isLoading">
    <dynamic-table title="Rooms" @delete="deleteRoom"></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <my-pagination
    v-if="!!getRooms.total"
    :options="options"
    v-model="page"
    :records="getRooms.total"
    :per-page="getRooms.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import dropDown from "@/components/ui/forms/dropDown.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";
export default {
  components: { dynamicTable, dropDown, smallForm, cardWidget, blankPaginate },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      tableHeaders: ["NAME", "PRACTICE", "CREATED", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Room Name",
          fieldName: "name",
        },
        // { type: "file", placeholder: "Attachment", fieldName: "attachment" },
      ],
      localInputs: {},
      practiceData: {},
      isLoading: false,
      newUserError: null,
      newRoomError: null,
      doc: "",
      icon: "",
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadRooms(this.page);
    this.loadPractices();
  },
  methods: {
    myCallback(e) {
      this.loadRooms(e);
    },
    uploadFile() {
      this.doc = this.$refs.file.files[0];
    },
    resolveOnChange(data) {
      this.localInputs[data.target.name] = data.target.value;
    },
    async loadPractices() {
      try {
        await this.$store.dispatch("admin/loadPractices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async addRoom(data) {
      try {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("practice", this.localInputs.practice);
        formData.append("icon", this.icon);
        await this.$store.dispatch("admin/addRoom", formData);
        this.newRoomError = null;
        this.isLoading = false;
        this.$toast.success(`Room Created!`);
      } catch (error) {
        this.error = error.message;
        this.newRoomError = error.message;
      }
    },
    async loadRooms(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadRooms", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // Delete Room
    async deleteRoom(id) {
      try {
        await this.$store.dispatch("admin/deleteRoom", id);
        this.isLoading = false;
        this.$toast.error(`Room Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getRooms() {
      return this.$store.getters["admin/getRooms"];
    },
    getPractices() {
      const data = this.$store.getters["admin/getPractices"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.practiceData[item.id] = item.practice_name;
        });
        return this.practiceData;
      } else {
        return this.practiceData;
      }
    },
  },
};
</script>
